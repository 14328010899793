<style>
.event-details-icon {
  cursor: pointer;
}
.event-details {
  display: none;
  -webkit-transition: all .4s;
  transition: all .4s;
}
.expanded-details .event-details {
  display: block;
}
</style>
<script>
import TimeAgo from "vue2-timeago";
import {mapGetters} from "vuex";
import {Game} from "@/enums";

export default {
  props: ['server_id', 'options', 'items', 'fields', 'currentPage', 'sortBy', 'sortDesc'],
  components: {
    TimeAgo,
    PlayerTableCellLogs: () => import('@/components/widgets/logs/PlayerTableCellLogs')
  },
  methods: {
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    toList(obj) {
      let results = [];
      let ignoredParameters = ['string', 'kick_reason'];
      Object.keys(obj).forEach((k) => {
        if(ignoredParameters.includes(k)) return;
        let v = obj[k];
        let s = null;
        switch(k) {
          case 'seconds': {
            k = 'duration';
            s = 'timeago';
            break;
          }
          default:
        }
        results.push({'k': k, 'v': v, 's': s});
      });
      return results;
    },
    async expandDetails(event) {
      if(event.target.parentElement.classList.contains('expanded-details'))
        event.target.parentElement.classList.remove('expanded-details')
      else event.target.parentElement.classList.add('expanded-details')
    },
    hasItems(map) {
      return Object.keys(map).length
    },
    getJSDate(v) {
      return new Date(Date.now() - 1000 * v * 60);
    },
    flattenObjectParameters(obj, prefix = '') {
      return obj;
    },
    actionTranslation(event) {
      event.parameters.position = event.parameters.player.position;
      let translationKey = `gameactions.${event.parameters.action}`;
      event.parameters.action_string = this.$t(translationKey);
      if(event.parameters.action_string === translationKey) event.parameters.action_string = event.parameters.action;
      if(event === 'player.interact') {
        if(event.parameters.action === 'ActionRaiseFlag') delete event.parameters.item;
        if(event.parameters.item) {
          if(!event.parameters.target) {
            event.parameters.target = event.parameters.item;
            return `${this.$t(`events.player.interact_woitem`, event.parameters)}`;
          } else return `${this.$t(`events.player.interact`, event.parameters)}`;
        } else {
          return this.$t(`events.player.interact_woitem`, event.parameters);
        }
      }
      return this.$t(`events.${event.event}`, event.parameters);
    },
    specialTranslation(event) {
      switch(event.event) {
        case 'player.place': {
          event.parameters.position = event.parameters.player.position;
          return `${this.$t(`events.player.place`, event.parameters)}`;
        }
        case 'player.death': {
          if(event.parameters.murderer) {
            return `${event.parameters.player.name} ${this.$t('events.player.killed')} ${event.parameters.murderer.name} (${event.parameters.weapon}, ${event.parameters.distance}m)`;
          } else {
            return `${event.parameters.player.name} ${this.$t('events.player.death')}`;
          }
        }
        case 'player.damage': {
          return `${event.parameters.player.name} ${this.$t('events.player.damaged')} ${event.parameters.murderer.name} (${event.parameters.zone}, ${event.parameters.weapon}, ${event.parameters.distance}m, ${event.parameters.damage} damage)`;
        }
        case 'player.envdeath': {
          if(event.parameters.cause === '__environment') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__infected') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_infected')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__bleedout') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_bleedout')} (${event.parameters.player.position})`;
          } else if(event.parameters.cause === '__starvation') {
            return `${event.parameters.player.name} ${this.$t('events.player.death_starvation')} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__animal') {
            return `${this.$t('events.player.death_animal', {animal: event.parameters.object})} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__object') {
            return `${this.$t('events.player.death_object', {object: event.parameters.object})} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__explosion') {
            return `${this.$t('events.player.death_explosion')} (${event.parameters.player.position})`;
          } else if (event.parameters.cause === '__fall_damage') {
            return `${this.$t('events.player.death_falldamage')} (${event.parameters.player.position})`;
          }
          return `${event.parameters.player.name} ${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
        }
        case 'player.suicide': {
          return `${event.parameters.player.name} ${this.$t('events.player.suicide')} (${event.parameters.player.position})`;
        }
        default: return event.event;
      }
    },
    translatePosition(pos) {
      // Statics for lat lang transformation
      let units = 90.0, map_size = 30720.0;

      let map_x = pos.coordinates[0];
      let map_y = pos.coordinates[1];

      let x = (map_x) / (units / map_size);
      let y = (((-1*units) - map_y) / (units / map_size)) * -1;
      return `${Number((x).toFixed(2))}, ${Number((y).toFixed(2))}`;
    },
    supportsDamageLogs() {
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsInteractLogs() {
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
  },
  computed: {
    ...mapGetters({
      getServer: 'getServer',
      getUILanguage: 'getUILanguage',
      getDTLocale: 'getDTLocale'
    })
  },
  data() {
    return {
      translatedEvents: [
        'rcon.connection_established',
        'rcon.downtime_cleared',
        'rcon.authentication_error',
        'user.kicked',
        'user.command',
        'user.join',
        'user.leave',
        'user.chat',
      ],
      actionEvents: [
        'player.interact',
      ],
      specialEvents: [
        'player.death',
        'player.damage',
        'player.envdeath',
        'player.suicide',
        'player.place',
      ],
      detailEvents: [
        'player.interact',
        'player.place',
        'rcon.downtime_cleared', // Display downtime
        'rcon.authentication_error', // Display downtime
        'player.death',
        'player.damage',
        'player.envdeath',
        'player.suicide',
        'user.join',
        'user.leave'
      ],
      blockedDetailKeys: [
        'cause',
        'queryables',
        'player',
        'murderer',
        'action_string'
      ],
    }
  }
};
</script>

<template>
  <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
    <template #empty="scope">
      <h4>{{ $t('components.table.filters.empty') }}</h4>
    </template>
    <template #cell(date)="data">
      {{ $d(parseDate(data.item.date), 'datetime', getDTLocale()) }}
    </template>
    <template #cell(position)="data">
      <template v-if="data.item.position">
        <h5 class="text-uppercase">
          {{ translatePosition(data.item.position) }}
        </h5>
      </template>
    </template>
    <template #cell(user)="data">
      <PlayerTableCellLogs :player="data.item.player" placeholder="system"/>
    </template>
    <template #cell(target)="data">
      <PlayerTableCellLogs :player="data.item.murderer"/>
    </template>
    <template #cell(event)="data">
      <span class="font-size-14 text-white" :class="{'event-details-icon': hasItems(data.item.parameters)}" v-on:click="expandDetails($event)">
        <template v-if="translatedEvents.includes(data.item.event)">
          {{ $t(`events.${data.item.event}`, data.item.parameters) }}
        </template>
        <template v-else-if="actionEvents.includes(data.item.event)">
          {{ actionTranslation(data.item) }}
        </template>
        <template v-else-if="specialEvents.includes(data.item.event)">
          {{ specialTranslation(data.item) }}
        </template>
        <template v-else>
          <span class="text-lowercase ">{{ data.item.event }}</span>
        </template>
      </span>
      <template v-if="detailEvents.includes(data.item.event)">
        <i class="fad fa-info-circle font-size-16 event-details-icon" v-if="Object.keys(data.item.parameters).length" v-on:click="expandDetails($event)"/>
        <div class="event-details" v-if="Object.keys(data.item.parameters).length">
          <div v-for="parameter in toList(data.item.parameters)" :key="parameter.k">
            <template v-if="!blockedDetailKeys.includes(parameter.k)">
              <template v-if="parameter.s === 'timeago'">
                <b class="text-code">{{ parameter.k }}: </b>
                <span class="text-code">
                      <time-ago :refresh="0" :datetime="getJSDate(parameter.v)" :locale="getUILanguage()"></time-ago>
                    </span>
              </template>
              <template v-else>
                <b class="text-code">{{ parameter.k }}:</b> <span class="text-code">{{ parameter.v }}</span>
              </template>
            </template>
          </div>
        </div>
      </template>
    </template>
    <template #cell(actions)="data">
      <template v-if="data.item.player.cftools_id">
        <router-link :to="{name: 'profile', params: {cftools_id: data.item.player.cftools_id}}" target="_blank">
          <button class="btn btn-sm btn-primary">
            <i class="fad fa-info" />
            {{ $t('server.view.players.profile') }}
          </button>
        </router-link>
      </template>
    </template>
  </b-table>
</template>
